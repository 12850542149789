<template>
  <div>
    <div class="row justify-content-end form-rounded-inputs ">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="input-group">
            <select name="" class="custom-select" v-model="questionsPagination.answered" @change="fnFirstPagination()">
              <!-- <option :value="null" v-text="$t('general.form.all')"> </option> -->
              <option :value="false" v-text="$t('dashboard.questions.new')"> </option>
              <option :value="true" v-text="$t('dashboard.questions.old')"> </option>
            </select>
            <div class="input-group-append">
               <button class="btn btn-success">
                <i class="fa fa-comments"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="(questionsList.length > 0) && !loading.data">
      <table class="table table-bordered ">
        <transition-group tag="tbody" class="list-container" :duration="{ leave: 1000 }" enter-active-class="animated bounceIn" leave-active-class="animated fadeOutRight">
          <tr v-for=" (question,index) in questionsList " v-bind:key=" question.id " class="animated fadeIn">
            <td>
              <div>
                <div class="row mb-1 " v-if="productId == null && (question.product != null) ">
                  <div class="col-auto pr-0">
                    <img v-image="question.product.imageUrl" class="img-contain-40" alt="">
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col-12">
                        <p class="m-0">
                          <router-link :to=" `/dashboard/products/${question.product_id}` " class="text-muted">
                            <small v-text=" question.product ? (question.product.name != 'notFound' ? question.product.name : $t('messages.productNotFound') ) : ( $t('tables.loading') ) "></small>
                          </router-link>
                        </p>
                      </div>
                      <div class="col-auto ml-auto">
                        <p class="text-muted text-right">
                          <small v-text=" fnFormatPrice( question.product.price , question.product.currency ) "></small>
                          <!-- <br> <small v-text=" fnGlobalGetTime( question.created_at ) "> </small> -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-none ">
                  <div class="col-10  ">
                  </div>
                  <div class="col-auto ml-auto d-none">
                    <button class="btn btn-link btn-xs p-0 " @click="question.showDeleteForm = !question.showDeleteForm"
                      v-text=" question.showDeleteForm ?  'Cancelar' : 'Denunciar' ">
                    </button>
                  </div>
                </div>
                <p class="mb-1">
                  <span v-text=" question.question "></span>
                  <small class="text-muted" v-text=" ' - ' + fnGlobalGetTime( question.created_at ) "> </small>
                </p>

                <div v-if=" question.answer ">
                  <p class="text-muted">
                    <i class="fa fa-level-up-alt fa-rotate-90 mx-2"></i>
                    <span v-text="question.answer"></span>
                    <small v-text=" ' - ' + fnGlobalGetTime( question.updated_at ) "></small>
                  </p>
                </div>
                <v-observer :ref="question.id" tag="form" class="animated fadeIn"
                  @submit.prevent="fnValidateForm(question.id)" v-show="!question.showDeleteForm" v-slot="{ invalid }"
                  v-else>
                  <div class="row align-items-center justify-content-end ">
                    <div class="col-12">
                      <v-validation rules="required|min:2" v-slot="{ errors }" :name="$t('general.form.answer')">
                        <textarea cols="30" rows="2" class="w-100 form-control mb-2 answer-area"
                          :class="{ 'is-invalid': errors.length > 0 }" v-model="question.tempAnswer"></textarea>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </v-validation>
                    </div>
                    <div class="col-auto">
                      <button class="btn btn-sm h-100" :disabled=" (tempIndex == index) || (tempIndex != null) "
                        :class=" ( !invalid) ? 'btn-success': 'btn-light' ">
                        <span v-text="$t('general.button.answer')" v-if="tempIndex != index"></span>
                        <i class="fa fa-spinner fa-spin" v-else></i>
                      </button>
                    </div>
                  </div>
                </v-observer>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>
      <div v-if=" questionsPagination.total > 0 && ( (questionsPagination.total / questionsPagination.limit) > 1 ) ">
        <b-pagination v-model="questionsPagination.page" :total-rows="questionsPagination.total"
        :per-page="questionsPagination.limit" @input="fnApiGetQuestions" align="fill"
        :first-text="$t('general.button.first')" :prev-text="$t('general.button.previous')" class="d-none d-md-flex"
        :next-text="$t('general.button.next')" :last-text="$t('general.button.last')" >

      </b-pagination>
       <b-pagination v-model="questionsPagination.page" :total-rows="questionsPagination.total"
        :per-page="questionsPagination.limit" @input="fnApiGetQuestions" align="fill" class="d-md-none">
      </b-pagination>
      
      </div>
      
    </div>
    <no-data v-if=" questionsList.length == 0 || loading.data " :isLoading="loading.data"
      :dataMessage="$t('noDataMessages.questionsList')" :showBtn="false">
    </no-data>
  </div>
</template>

<script>
  import {
    mapActions,
    mapMutations,
    mapState
  } from 'vuex';

  export default {
    props: {
      productId: {
        type: String,
      }
    },
    data() {
      return {}
    },
    computed: {
      ...mapState('productQuestions', ['loading', 'questionsList', 'questionsPagination', 'tempIndex']),
      ...mapState('EcartProducts', ['productsList']),
    },
    watch: {
      async questionsList() {
        let tempList = [];
        this.questionsList.map(question => {
          tempList.push(question.product_id);
        })
        await this.fnApiGetProductsInformation(tempList);
        this.fnSetProducts();
      },
      productsList() {
        this.fnSetProducts();
      }
    },
    methods: {
      ...mapMutations('productQuestions', ['setProductId', 'resetPagination']),
      ...mapActions('productQuestions', ['fnFirstPagination', 'fnResetPagination', 'fnApiAnswer', 'fnApiGetQuestions']),
      ...mapActions('EcartProducts', ['fnApiGetProductsInformation']),

      async fnValidateForm(questionId) {
        if (await this.$refs[questionId][0].validate()) {
          await this.fnApiAnswer(questionId);
          if (this.questionsList.length == 0) {
            this.$emit('close');
          } else {}
        } else {}
      },

      fnSetProducts() {
        this.questionsList.map(question => {
          if ((question.product == null) || (question.product == undefined)) {
            let tempProduct = this.productsList.find(product => (product.id == question.product_id));
            if (tempProduct != undefined) {
              question.product = tempProduct;
            }
          }
        })
      },

    },
    mounted() {
      if (this.productId) {
        this.fnResetPagination(this.productId);
      } else {
        this.fnResetPagination();
      }
    },
  }
</script>
<style>
  .answer-area {
    resize: none;
  }

  /* .collapse-content {
    transition-delay: 1s height;
    animation-name: collapse-content;
    animation-duration: 1s;
    animation-iteration-count: linear;
    overflow: hidden;
  }

  @keyframes collapse-content {
    from {
      opacity: 1;
      height: 200px;
    }

    to {
      opacity: 0;
      height: 0px;
    }
  } */
</style>